import React from 'react';
import Timer from './Timer';
import posthog from 'posthog-js'

posthog.init('phc_bFU6xzCm8IlYvs7VjFyelxvY68XD0k97R7vA0vTCRrl',
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
    }
)
function App() {
  return (
    <div className="App">
      <Timer />
    </div>
  );
}

export default App;