import React, { useState, useEffect } from 'react';
import './App.css';
import logo from './logo.svg';
import drum from './drum.mp3';
import bell from './bell.mp3';

const Timer = () => {
  const [count, setCount] = useState(17);
  const [isRunning, setIsRunning] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [manifestationSubject, setManifestationSubject] = useState('');

  const drumSound = new Audio(drum);
  const bellSound = new Audio(bell);

  useEffect(() => {
    // Preload the bell sound
    bellSound.load();
  }, []);

  const handleManifestationSubjectChange = (e) => {
    setManifestationSubject(e.target.value);
  };

  const saveAndProceed = () => {
    localStorage.setItem('manifestationSubject', manifestationSubject);
    setCurrentStep(1);
    bellSound.play(); // Play bell sound when "Let's go!" button is clicked
  };

  const startTimer = () => {
    if (isRunning) return;

    setIsRunning(true);
    setCount(17); // Start the countdown from 17

    drumSound.play();

    const circle = document.querySelector('svg circle');
    circle.style.animation = 'none';
    setTimeout(() => {
      circle.style.animation = 'countdown 17s linear forwards';
    }, 0);

    const timerInterval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount <= 1) { // Stop the timer at 0
          clearInterval(timerInterval);
          setIsRunning(false);
          bellSound.play(); // Play bell sound when timer ends
          moveToNextSection();
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);
  };

  const reset = () => {
    setCurrentStep(0);
    setCount(17); // Reset the count to 17
    setIsRunning(false);
  };

  const moveToNextSection = () => {
    switch (currentStep) {
      case 1:
        setCurrentStep(2);
        break;
      case 2:
        setCurrentStep(3);
        break;
      case 3:
        setCurrentStep(4);
        break;
      case 4:
        setCurrentStep(5);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isRunning) {
      const countdownEl = document.getElementById('countdown-number');
      countdownEl.textContent = count;
    }
  }, [count, isRunning]);

  return (
    <div className="appWrapper">
      {currentStep === 0 && (
        <section id="Welcome">
          <div className="container">
            <img src={logo} className="App-logo" alt="logo" style={{ maxWidth: '100px' }} />
            <h2>Today I am manifesting...</h2>
            <input
              type="text"
              value={manifestationSubject}
              onChange={handleManifestationSubjectChange}
              placeholder="Type here (e.g. deep peace)..."
            />
            <button onClick={saveAndProceed}>Let's go!</button>
            <p className="sponsor">17seconds is brought to you by<br /><a href="https://heysolace.com" target="_blank" rel="noopener noreferrer" style={{textDecoration:'none'}}><u><strong>HeySolace</strong></u> (24/7 talk therapy from $11 per month)</a></p>
            <p className="madeBy">Made with ♥ by <a href="https://x.com/HelpfulApps" target="_blank" rel="noopener noreferrer">HelpfulApps</a></p>
          </div>
        </section>
      )}
      {currentStep >= 1 && currentStep <= 4 && (
        <section id={["Mantras", "Visualisation", "Gratitude", "Meditation"][currentStep - 1]}>
          <div className="container">
            <h2>{["Mantras", "Visualisation", "Gratitude", "Meditation"][currentStep - 1]}</h2>
            <p>
              {[
                `Start with repeating or writing your manifestation affirmation for 17 seconds to move into alignment 
                (e.g. I am so happy and grateful to be receiving ${manifestationSubject}, I love seeing how ${manifestationSubject} is showing up for me right now).`,
                `Visualise your manifestation subject for 17 seconds with intent and clarity 
                (see and feel the effect of having ${manifestationSubject} already, see all the little details, imagine where and how that shows up for you).`,
                `Spend 17 seconds feeling gratitude for what you have and what is to come 
                (e.g. I'm so grateful to have ${manifestationSubject} in my life, I'm so thankful for all the ways I have something like this already, etc).`,
                `Try a smiling meditation for 17 seconds to connect with joy and your higher self 
                (imagine and feel the having of ${manifestationSubject} now, sink into the peace in your heart, feel the joy in your crown, say "yes" in your mind).`
              ][currentStep - 1]}
            </p>
            <div id="countdown">
              <div id="countdown-number">{count}</div>
              <svg>
                <circle r="140" cx="180" cy="171"></circle>
              </svg>
            </div>
            <button
              id="startButton"
              className={isRunning ? 'inactive' : ''}
              onClick={startTimer}
            >
              Start
            </button>
          </div>
        </section>
      )}
      {currentStep === 5 && (
        <section id="End">
          <div className="container">
            <h2>What's Next?</h2>
            <button className="repeatBtn" onClick={() => setCurrentStep(1)}>Repeat</button>
            <button className="letgoBtn" onClick={reset}>Release!</button>
            <p className="sponsor">17seconds is brought to you by<br /><a href="https://heysolace.com" target="_blank" rel="noopener noreferrer" style={{textDecoration:'none'}}><u><strong>HeySolace</strong></u> (24/7 talk therapy from $11 per month)</a></p>
            <p className="madeBy">Made with ♥ by <a href="https://x.com/HelpfulApps" target="_blank" rel="noopener noreferrer">HelpfulApps</a></p>
          </div>
        </section>
      )}
      {currentStep > 0 && (
        <a href="#" onClick={reset} className="resetLink">
          Reset
        </a>
      )}
    </div>
  );
};

export default Timer;